import  React, { useState, useRef, useEffect, useContext  } from "react"; 
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify"; 
import axios from 'axios';
import { Icons } from "../../assest/icons";
import Modal from 'react-modal';  
import Header from './header';
import { SearchContext } from './SearchContext'; 
import LoaderSpinner from '../loaderSpinner';
import SideBarMenu from './sideBarMenu'; 
import {getBaseURL} from '../baseUrl'; 
import moment from 'moment'; 


 
function ZoomCallLink() { 
  const location = useLocation(); 
  const [SessionIDCheck, setSessionIDCheck, summaryTxt, followSummaryTxt] = useState('');
  console.log(summaryTxt)
  console.log(followSummaryTxt)
  const [reportData, setReportData] = useState(null);
  const [PCP_Pharmacy, setPCP_Pharmacy] = useState(null);
  const [showModal, setShowModal] = useState(false); // Control modal visibility
  const [selectedFile, setSelectedFile] = useState(null); // Store selected file info 

  const { sessionid, useridchk } = location.state || {}; 

  const [ShowLoaderPage, setShowLoaderPage] = useState(false);
  const [userid, setuserid] = useState(useridchk); 
  const [adminId, setAdminId] = useState(JSON.parse(localStorage.getItem('UserIdAdmin'))); 

  
  const [userProfileData, setUserProfileData] = useState([]);
  const [UserHistoryData, setUserHistoryData] = useState([]);
  const [UserHistoryNumber, setUserHistoryNumber] = useState('');
  const [ChatHistory, setChatHistory] = useState([]);
  const [RecordData, setRecordData] = useState([]);  
  const [BackReload, setBackReload] = useState(false);   
const [fullName, setFullName] = useState('');
const [email, setEmail] = useState('');
const [shareEmail, setShareEmail] = useState([]);
const [emailList, setEmailList] = useState("");
const [responseEmail, setResponseEmail] = useState(null);
const [isLoadingEmail, setIsLoadingEmail] = useState(false);
const [isLoadingEmailP, setIsLoadingEmailP] = useState(false);
const [phoneNumber, setPhoneNumber] = useState('');
const [userProfile, setUserProfile] = useState('');  
const [specialty, setSpecialty] = useState('');
const [selectedToggle, setSelectedToggle] = useState(true);
const [DOBTxt, setDOBTxt] = useState(''); 
const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
const [GenderTxt, setGenderTxt] = useState('');
const [originalProfile, setOriginalProfile] = useState({});
const [isEditing, setIsEditing] = useState(false);
const [isEditingCD, setIsEditingCD] = useState(false);
const [isEditingFollowCD, setIsEditingFollowCD] = useState(false);
const [isEditingFollowPoC, setIsEditingFollowPoC] = useState(false);
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');   
var years = moment().diff(DOBTxt);
const ageCal = moment().diff(DOBTxt, 'years'); 
const [BMITxt, setBMITxt] = useState('');   

const [AgeTxt, setAgeTxt] = useState(ageCal);  
const [WeightTxt, setWeightTxt] = useState('');
const [HeightTxt, setHeightTxt] = useState('');
const [ShowLoader, setShowLoader] = useState(false); 
const [ShowLoaderSummaryAIChatbot, setShowLoaderSummaryAIChatbot] = useState(false); 
const [ShowLoaderZoomSummary, setShowLoaderZoomSummary] = useState(false); 
const [ShowLoaderSummaryFollowup, setShowLoaderSummaryFollowup] = useState(false); 

const [UserId, setUserId] = useState(userid);  
const [showCalendar, setShowCalendar] = useState(false);  
const [isValid, setIsValid] = useState(false);
const [isSession, setIsSession] = useState(false);

const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage, setRecordsPerPage] = useState(10);
const [showSummay, setShowSummary] = useState(false);
const totalPages = Math.ceil(UserHistoryData.length / recordsPerPage);
const totalPageGroups = Math.ceil(totalPages / 3);
const currentPageGroup = Math.ceil(currentPage / 3);

const [illnessesList, setIllnessesList] = useState([]);
const [selectedIllness, setSelectedIllness] = useState(null);
const [answerLoader, setAnswerLoader] = useState(false);
const [EmergencyQuest, setEmergencyQuest] = useState(false);
 
const [activeTab, setActiveTab] = useState(0);
const { searchQuery } = useContext(SearchContext);

const [MainPointsDiscussed, setMainPointsDiscussed] = useState('');
const [PlanOnCareData, setPlanOnCareData] = useState('');
const [ConditionalDiagnosis, setConditionalDiagnosis] = useState('');
const [followConditionalDiagnosis, setFollowConditionalDiagnosis] = useState('');
const [followPlanOnCareData, setFollowPlanOnCareData] = useState('');
const [zoomPoC, setZoomPoC] = useState('');
const [zoomCD, setZoomCD] = useState('');


const [followSummaryEditFollowup, setFollowSummaryEditFollowup] = useState('');
const [followSummaryEditAIChatbot, setFollowSummaryEditAIChatbot] = useState('');
const [editZoomSummary, setEditZoomSummary] = useState('');

const [summaryEditFollowup, setSummaryEditFollowup] = useState('');

const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  

const [myinsuranceinfo, setMyinsuranceinfo] = useState([]);


const [documents, setDocuments] = useState([]); // Document list state
const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
const [isModalPhysicalExamOpen, setIsModalPhysicalExamOpen] = useState(false); // Modal open/close state
const [selectedDocument, setSelectedDocument] = useState(null); // Track the selected document
const [isModalOpenViewAll, setIsModalOpenViewAll] = useState(false); // Modal open/close state

const [SessionID, setSessionID] = useState('');

const [patientbpMMTxt, setpatientbpMMTxt] = useState('');
const [bpMMTxt, setbpMMTxt] = useState('');
const [bpHgTxt, setbpHgTxt] = useState('');
const [heartrateTxt, setheartrateTxt] = useState('');

const [editbpMMTxt, seteditbpMMTxt] = useState('');
const [editbpHgTxt, seteditbpHgTxt] = useState('');
const [editheartrateTxt, seteditheartrateTxt] = useState('');

const [errorMessage, setErrorMessage] = useState('');
const [errorShare, setErrorShare] = useState(""); // To store validation errors
const [errors, setErrors] = useState({}); 

const bpMMRef = useRef(null);
const bpHgRef = useRef(null);
const hiddenDivRef = useRef(null); 

const [CheckBpMMTxt, setCheckBpMMTxt] = useState(bpMMTxt);
const [CheckBpHgTxt, setCheckBpHgTxt] = useState(bpHgTxt);
const [CheckHeartRateTxt, setCheckHeartRateTxt] = useState(heartrateTxt);

const [patientstatus, setPatientstatus] = useState('');

const [isOpen, setIsOpen] = useState(false); // Track if the div is open
const [selectedItem, setSelectedItem] = useState(null); // Button name
const [activeItem, setActiveItem] = useState(null); // Track selected list item 
const [isOpenPhysicalExam, setIsOpenPhysicalExam] = useState(false); // Track if the div is open
const [checkPhysicalExam, setCheckPhysicalExam] = useState(false); // Track if the div is open
const [filledCheckPhysicalExam, setfilledCheckPhysicalExam] = useState(false); // Track if the div is open

const [EditDurationTxt, setEditDurationTxt] = useState('');
const [EditDurationTxtTemp, setEditDurationTxtTemp] = useState(EditDurationTxt);
const [isOpenEditDuration, setIsOpenEditDuration] = useState(false); // Track if the div is open
const [checkEditDuration, setCheckEditDuration] = useState(false); // Track if the div is open
const [filledCheckEditDuration, setfilledCheckEditDuration] = useState(false); // Track if the div is open

// const [selectedItemFollowup, setSelectedItemFollowup] = useState(null); // Button name   
const [isOpenFollowup, setIsOpenFollowup] = useState(false); // Track if the div is open
const [checkFollowup, setCheckFollowup] = useState(false); // Track if the div is open
const [filledCheckFollowup, setfilledCheckFollowup] = useState(false); // Track if the div is open
const [followupDays, setFollowupDays] =  useState(new Date()); //useState('');
const [followupTime, setFollowupTime] =  useState(new Date()); //useState('');
const [followupDate, setFollowupDate] =  useState(new Date()); //useState('');
const [futureDate, setFutureDate] = useState(null);
const [remainingDays, setRemainingDays] = useState(null);
const now = new Date();
const [tempBpMMTxt, setTempBpMMTxt] = useState(''); // Temporary Mm value 
const [openDiv, setOpenDiv] = useState(null);


const [followupZoomMeet, setFollowupZoomMeet] = useState(false); 
const [checkFollowupMeet, setCheckFollowupMeet] = useState(false); 
const [checkAllFollowupMeet, setCheckAllFollowupMeet] = useState(false); 

const [selectedItemFollowup, setSelectedItemFollowup] = useState([]); // Followup Date 
const [FollowupData, setFollowupData] = useState([]); // Followup Date 
const [MeetingDuration, setMeetingDuration] = useState([]); // Followup Date 

const [activeOption, setActiveOption] = useState('Routine'); // State to track the active option

const [meetingStatusZoom, setMeetingStatusZoom] = useState(''); // Button name   
const [dataProcessingStatus, setDataProcessingStatus] = useState([]); // Button name  

const [selectedZoomMeet, setSelectedZoomMeet] = useState([]); // Button name   
const [isOpenZoomMeet, setIsOpenZoomMeet] = useState(false); // Track if the div is open
const [checkZoomMeet, setCheckZoomMeet] = useState(false); // Track if the div is open 
const [meetingTitle, setMeetingTitle] = useState('AIGI Online Consultation');
const [duration, setDuration] = useState(0);  
const [startTime, setStartTime] = useState(new Date());
const [startDate, setStartDate] = useState(new Date());

const [activeSpeech, setActiveSpeech] = useState(null);
const [transcripts, setTranscripts] = useState({ medicalcondition: ''});
const [MedicalConditionsData, setMedicalConditionsData] = useState('');

const [CheckExpiry, setCheckExpiry] = useState(false);  

const [activeKey, setActiveKey] = useState(null);
// const [Icons, setIcons] = useState([{ editIcon: 'editIcon', editRed: 'editRed', admin_icon911: 'admin_icon911' }]);

const [editMode, setEditMode] = useState(null); // Track which follow-up is in edit mode
const [editText, setEditText] = useState(''); // Track the current text being edited

 

const checkOrderList = [
  { Name: "Allergies", doc_type: "allergy" },
  { Name: "Medications", doc_type: "medications" },
  { Name: "Medical Conditions", doc_type: "medical_conditions" },
  { Name: "Past Surgical History", doc_type: "past_surgical_history" },
  { Name: "Social History", doc_type: "social_history" },
  { Name: "Primary Care Physician (PCP)", doc_type: "pcp" },
  { Name: "Pharmacy information", doc_type: "pharmacy" },
  { Name: "Upload Reports", doc_type: "other_report" }
];



const [activeAccordion, setActiveAccordion] = React.useState(null); 
const [editingAccordion, setEditingAccordion] = useState(null);
const [updatedContent, setUpdatedContent] = useState({}); // Object to track updates for each accordion


useEffect(() => {  
      
    const fetchRecords = async () => {
    
      setShowLoader(false)
 
      try {
        // setShowLoaderPage(true)
        // Make a GET request to fetch records from the API
        const response = await axios.get(getBaseURL+'/get_user_sessions/'+userid, {
          headers: {
            'Content-Type': 'application/json, text/html', // Specify the Content-Type header
          },
        });
        console.log(response.data)
        setShowLoader(false)
        // Set user profile data
        setUserProfileData(response.data.user_details);  
      // Sort history data by created_on date in descending order
        const sortedHistory = [...response.data.history];  
        setUserHistoryData(sortedHistory);
        setUserHistoryNumber(response.data.history.length); 
        setBMITxt(response.data.bmi ? response.data.bmi : 0);
        setFullName(response.data.user_details.fullName);
        setEmail(response.data.user_details.email);
        setDOBTxt(response.data.user_details.dob);
        setPhoneNumber(response.data.user_details.phoneNumber ? '+'+response.data.user_details.phoneNumber : '');
        setUserProfile(response.data.user_details.userProfile);
        setInsuranceIdTxt(response.data.user_details.insurance ? response.data.user_details.insurance : 0);
        setGenderTxt(response.data.user_details.gender ? response.data.user_details.gender : '');
        setHeightTxt(response.data.user_details.height ? response.data.user_details.height : 0);
        setWeightTxt(response.data.user_details.weight ? response.data.user_details.weight : 0);        
        setIsSession(response.data.session_status ? response.data.session_status : 'false');  
        handleShowSummary(sessionid)  
        setShowLoader(false);    
    
      } catch (error) {
        console.error('Error fetching records:', error);
        // Handle error, show an error message, etc.
      }
      
   
    };
    fetchRecords();
  }, []); 

const calculateDurationInMinutes = (startTs, endTs) => { 
  const durationInSeconds = endTs - startTs;
  const durationInMinutes = Math.floor(durationInSeconds / 60);
  return durationInMinutes;
};
const calculateDuration = (startTs, endTs) => { 
  const durationInSeconds = endTs - startTs;
  const durationInMinutes = Math.floor(durationInSeconds / 60);
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  const hourString = hours > 0 ? `${hours} hour` : "";
  const minuteString = minutes > 0 ? `${minutes} minute` : "";

  return [hourString, minuteString].filter(Boolean).join(" and ");
};

 

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/admin/patientprofile`;
    navigate(path);
  }
  const nav = useNavigate();  

  const adminCheckedSession = async (patientId, sessionId) => {
    try {
      const response = await axios.post(`${getBaseURL}/admin_log`, {
        
          patient_id: patientId,
          admin_id: adminId,
          session_id: sessionId,  
      
      }); 
  
    
      if( response.data.status == 'success'){ 
        setBackReload(true)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setShowLoader(false);
    }
  }


  const showPatientDataResult = async (foundRecord) => { 
    const filteredConversation = foundRecord.conversation.filter(convo => Object.keys(convo).length !== 0); 

    // Set all necessary states based on foundRecord
    setRecordData(foundRecord);
    setFollowSummaryEditAIChatbot(foundRecord.chatbot_summary) 
    setSelectedIllness(foundRecord.illness);
    setChatHistory(filteredConversation);
    setPlanOnCareData(foundRecord.plan_of_care); 
    setConditionalDiagnosis(foundRecord.conditional_diagnosis);
    setSessionIDCheck(foundRecord.session_id);
    setPatientstatus(foundRecord.patientstatus);
    setSelectedItem(foundRecord.patientstatus);
    setActiveItem(foundRecord.patientstatus);
    setbpHgTxt(foundRecord.bp_hg);
    setbpMMTxt(foundRecord.bp_mm);
    setheartrateTxt(foundRecord.heartrate);
    setHeightTxt(foundRecord.height ? foundRecord.height : 0);
    seteditbpHgTxt(foundRecord.bp_hg);
    seteditbpMMTxt(foundRecord.bp_mm);
    seteditheartrateTxt(foundRecord.heartrate); 

    setpatientbpMMTxt(foundRecord.bmi);
    setTempBpMMTxt(foundRecord.bp_mm);
    setFutureDate(foundRecord.follow_up_date);
    // setSelectedItemFollowup(foundRecord.follow_up_date);
    setSelectedZoomMeet(foundRecord?.zoom_meeting || null);
    setMeetingStatusZoom(foundRecord?.zoom_meeting?.meeting_status);
    setDataProcessingStatus(foundRecord?.zoom_meeting?.data_processing_status);
    setEditDurationTxt(calculateDuration(foundRecord?.zoom_meeting?.start_ts, foundRecord?.zoom_meeting?.end_ts))
    // Check if the answer to the specific question is "yes"
    const emergencyQuestion = filteredConversation.find(convo =>
      convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
    );
  
    if (emergencyQuestion) {
      setEmergencyQuest(true);
    }
  
    setChatHistory([]);
    setShowSummary(true);
  
    if (foundRecord.session) {
      adminCheckedSession(useridchk, sessionid);
    }
    try {
      ///api/doctor/followup/<session_id>/active_request
      const response = await axios.get(getBaseURL+'/api/doctor/followup/'+sessionid+'/all_request'); 
      if (response.status === 200 || response.status === 201) {
        if (response?.data?.data) {
          const sortedData = [...response.data.data].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
          setFollowupData(sortedData); 

        }   
      } 
      
      } catch (error) {
       
      } finally {
        
      }
      try {
        ///api/doctor/followup/<session_id>/active_request
        const response = await axios.get(getBaseURL+'/api/doctor/followup/'+sessionid+'/active_request'); 
 
        if (response.status === 200 || response.status === 201) { 
          const { activeFlag, data } = response.data;
        
          // Set the check for all follow-up meetings
          setCheckAllFollowupMeet(activeFlag); // true if activeFlag is false, and vice versa 
          // Update selected follow-up item
          setSelectedItemFollowup(data);
        
          // Check if zoom meeting exists
          if (data.zoom_meeting && Object.keys(data.zoom_meeting).length > 0) {
            setFollowupZoomMeet(true);
          } 
        
          // Check for patient request
          if (data.patient_request) {
            setCheckFollowupMeet(true);
          }  
        }

        } catch (error) {  
        
        } finally {
          
        } 
      
   

    try { 
      const url = `${getBaseURL}/api/medical/reports/fetch/${foundRecord.reports_id}`;
      // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
        
      if (response.data && response.data.status === 'success') { 
        setReportData(response.data.content);
      }
      } catch (error) {
        //console.error('Error posting data:', error);
     } finally {
        
      }

      try { 
        const url = `${getBaseURL}/api/patient/fetch/details/pcp-pharmacy/${foundRecord.reports_id}`;
        // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
          
        if (response.data && response.data.status === 'success') {  
          setPCP_Pharmacy(response.data.data); 
        }
        } catch (error) { 
          //console.error('Error posting data:', error);
       } finally {
        setShowLoader(false);
        }
      


  };
  
  // Group reportData by doc_type
  const groupedDocuments = (reportData || []).reduce((acc, doc) => {
    if (!doc.doc_type) {
      return acc;
    }
    const docType = doc.doc_type.trim();

    if (!acc[docType]) {
      acc[docType] = [];
    }
  
    acc[docType].push({
      doc_id: doc.doc_id,
      doc_summary: doc.doc_summary,
      doc_text: doc.doc_text,
      doc_url: doc.doc_url,
    });
 

  return acc;
}, {});
 
if (PCP_Pharmacy && PCP_Pharmacy.pcp && PCP_Pharmacy.pcp.name && PCP_Pharmacy.pcp.address) {
groupedDocuments["pcp"] = [
  ...(groupedDocuments["pcp"] || []),
  {
    doc_summary: `${PCP_Pharmacy.pcp.name}, ${PCP_Pharmacy.pcp.address}`,  
    doc_text: null,
    doc_url: null,
  },
];
}
// Push Pharmacy into groupedDocumentspharmacy
if (PCP_Pharmacy && PCP_Pharmacy.pharmacy && PCP_Pharmacy.pharmacy.name && PCP_Pharmacy.pharmacy.address) {
groupedDocuments["pharmacy"] = [
  ...(groupedDocuments[""] || []),
  {
     doc_summary: `${PCP_Pharmacy.pharmacy.name}, ${PCP_Pharmacy.pharmacy.address}`, 
     doc_text: null,
     doc_url: null,
  },
];
}  


// Map checkOrderList to match groupedDocuments and sort by order
const sortedGroupedDocuments = checkOrderList.map(orderItem => ({
  Name: orderItem.Name,
  doc_type: orderItem.doc_type,
  documents: groupedDocuments[orderItem.doc_type] || []
})); 
 
  
const handleShowSummary = async (sessionid) => {
  setShowLoader(true);

  try {
    const foundRecord = UserHistoryData.find((item) => item.session_id === sessionid);
 
      // const foundRecord = UserHistoryData.find((item) => item.created_on === useridchk);

    if (foundRecord) {  
      if (foundRecord?.zoom_meeting?.meeting_status === 'active') {

        const data = {
          meeting_id: foundRecord?.zoom_meeting?.meeting_id,
          session_id: foundRecord?.session_id,
        }; 
        try {
          const response = await fetch(getBaseURL + '/api/zoom/process/meeting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          const result = await response.json(); 
          if (response.status === 200 || response.status === 201) { 
            showPatientDataResult(result.session_data);
          } else if (response.status === 400) {
            // toast.error(result.message || 'User not found or metrics not updated.', {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            showPatientDataResult(foundRecord);
          } else {
            toast.error(result.message || 'An unexpected error occurred. Please try again.', {
              position: toast.POSITION.TOP_RIGHT,
            });
            showPatientDataResult(foundRecord);
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error(error.message || 'Network error. Please try again later.', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        showPatientDataResult(foundRecord);
      }
    } else {
      setRecordData(null);
    }
  } catch (error) {
    console.error('Error in handleShowSummary:', error);
    toast.error('An error occurred. Please try again.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    setShowLoader(false);

  }
  
};

 // Open modal and set selected document
const openModal = (doc) => {   
  const viewerUrl = `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(doc)}`;
    setSelectedDocument(viewerUrl); 
    setIsModalOpen(true);
}; 

  return (
    <div>
    {ShowLoader && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
             <Header />
             <div className="innerBodyBox bgInnerDiv">

              <div className="contentInnerBody">
                <div className="titleDiv">
                  <h2>
                    {/* Procedures List */}
                    Online Consultation 
                    </h2> 
                </div>
                <div className="contentMainBox scrollHeightOverflow d-flex p-2"> 
                
               <div  className="w-50">

                test
                <div className="despInfoDiv">
                <div>{summaryTxt}</div>
                <div>{followSummaryTxt}</div>
                            <h2>Reports </h2>
                            </div>
                            <div className="innerDivBoxPC h-auto overflow-hidden"> 
                              
                    
                    <ol className="reportListOptLink" style={{fontSize:'16px' }}>
                    {sortedGroupedDocuments.length > 0 ? (
                      sortedGroupedDocuments
                        .filter(({ documents }) => documents && documents.length > 0) // Filter out empty or null documents
                        .map(({ doc_type, documents, Name  }) => (
                          <li key={doc_type}>
                        
                            {documents.map((doc, index) => (
                              <div key={doc.doc_id} style={{ marginBottom: "0px" }}>
                                  <b style={{ textTransform: "capitalize", textDecoration: "none" }} className={ doc_type === "other_report"  && index !== 0 && 'd-none'}>
                                  {Name} 
                                  {/* {doc_type.replace('_', ' ')} */}
                                  </b>
                                {/* Document URL with conditional button */}
                                {doc.doc_url && (
                                  <span
                                    className={
                                      "pdfButtonModal" + (doc_type === "other_report" ? " d-none" : "")
                                    }
                                  >
                                    <button
                                      className="pdfButton pdfViewBtn"
                                      onClick={() => openModal(doc?.doc_url)}
                                    >
                                      View{" "}
                                      <img
                                        src={Icons[0].viewPDF}
                                        alt="PDF Icon"
                                        style={{ width: "24px", marginLeft: "10px" }}
                                      />
                                    </button>
                                  </span>
                                )}

                                {/* Document Text */}
                                {/* Document Text */}
                                {doc.doc_text &&(
                                  <p style={{fontSize:'16px', marginBottom: "0" }}>{doc.doc_text}</p>
                                )}

                                <div className={"d-flex justify-content-between align-items-center "  +(doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null) }>
                                  <h3
                                    style={{
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      fontWeight: "600",
                                    }}
                                    className="my-0"
                                  >
                                  {
                                      doc_type === "other_report" ? (
                                        <>
                                          {index + 1}. Other Report Summary
                                        </>
                                      ) : doc_type === "pcp" || doc_type === "pharmacy" ? (
                                          null
                                      ) : doc.doc_summary ? (
                                        <>Report Summary</>
                                      ) : null
                                    }
                                  </h3>

                                  {/* Document URL Button (specific condition) */}
                                  {doc.doc_url && (
                                    <span
                                      className={
                                        "pdfButtonModal" +
                                        (doc_type !== "other_report" ? " d-none" : "")
                                      }
                                    >
                                      <button
                                        className="pdfButton pdfViewBtn"
                                        onClick={() => openModal(doc?.doc_url)}
                                      >
                                        View{" "}
                                        <img
                                          src={Icons[0].viewPDF}
                                          alt="PDF Icon"
                                          style={{ width: "24px", marginLeft: "10px" }}
                                        />
                                      </button>
                                    </span>
                                  )}
                                </div>

                                {/* Document Summary */}
                                {
                                  doc.doc_summary ? (
                                    <pre
                                      style={{ margin: "5px 0", textTransform: "capitalize" }}
                                      className={doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null}
                                    >
                                      {doc.doc_summary}
                                    </pre>
                                  ) : null
                                }

                                {/* Conditional Divider */}
                                {doc.doc_url && (
                                  <hr className={doc_type !== "other_report" ? " d-none" : ""} />
                                )}
                              </div>
                            ))}
                          </li>
                        ))
                    ) : (
                      <p style={{ textAlign: "center" }}>No reports found</p>
                    )}
                  </ol>


                        
                            </div>
 
               </div>
               <div className="w-50">
               <iframe className="w-100 h-100" src="https://zoom.us/s/93427276818?zak=eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJpc3MiOiJ3ZWIiLCJjbHQiOjAsIm1udW0iOiI5MzQyNzI3NjgxOCIsImF1ZCI6ImNsaWVudHNtIiwidWlkIjoiYzdwY1UtYnlRSXk3TkFmZnNYNzlhdyIsInppZCI6ImM0MTFkMzExNTE0ZTRlM2RhNzAwMTM5YzdjNGE4NmNhIiwic2siOiIwIiwic3R5IjoxMDAsIndjZCI6ImF3MSIsImV4cCI6MTczNjk0NDMwMSwiaWF0IjoxNzM2OTM3MTAxLCJhaWQiOiJFanlOOXVVZlRTNndFY1hNenBGSUhBIiwiY2lkIjoiIn0.n7RCrICquVKWA3v7zv1Le5e7QhdVKBDALl6n3qlP1To"></iframe>
 
               </div>
          
                          
 
                    
                
               
                       
                </div>
              </div>
                 
             </div>
        </div> 
      </div>
    </div>
  );
}

export default ZoomCallLink;