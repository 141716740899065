import React, { useState, useEffect, useRef } from "react";   
import * as RecordRTC from 'recordrtc';
import axios from 'axios';
import Header from './header';
import { Icons } from "../assest/icons";
import { ToastContainer, toast } from "react-toastify";   
import { useNavigate } from "react-router-dom";
import {getBaseURL} from './baseUrl';

function ChatBot() {
  const reportId =  JSON.parse(localStorage.getItem('reportId'))
  const [FinishChat, setFinishChat] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [illnessesList, setIllnessesList] = useState([]);
  const [selectedIllness, setSelectedIllness] = useState(null);
  const userId = JSON.parse(localStorage.getItem('UserId'));
  const usernameTxt = JSON.parse(localStorage.getItem('UserProfile'));
  const [UserName, setUserName] = useState(usernameTxt.fullName);
  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [questionLoader, setQuestionLoader] = useState(false);
  const [answerLoader, setAnswerLoader] = useState(false);
  const [nextQuestionLoader, setNextQuestionLoader] = useState(false);
  const messageBoxRef = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [MessageTxt, setMessageTxt] = useState('');
  
  const socket = useRef(null);
  const recorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [transcripts, setTranscripts] = useState('');
  const [activeMic, setActiveMic] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false); 
 
  const inputRefAnswer = useRef(null);

  useEffect(() => {
    if (inputRefAnswer.current) {
      inputRefAnswer.current.focus();
    }
  }, [answer]); 


const getToken = async () => {
    const response = await fetch(getBaseURL+'/new_token'); // Update this URL to your actual token endpoint
    const data = await response.json();
    if (data.error) {
      console.log(data.error);
      return null;
    }
    return data.token;
  };

  const generateTranscript = async () => {
    try {
      const token = await getToken();
      if (!token) return;

      setIsConnecting(true);
      socket.current = new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`);

      const texts = {};
      socket.current.onmessage = (voicePrompt) => {
        let msg = answer || '';        
        const res = JSON.parse(voicePrompt.data);
        if (res.text && res.text.trim() !== '') {  // Check if res.text is not null or blank
          texts[res.audio_start] = res.text;
        }

      
        const keys = Object.keys(texts).filter(key => key !== 'undefined').sort((a, b) => a - b);
        keys.forEach((key) => {
          msg += ` ${texts[key]}`;
        }); 
        setAnswer(msg.trim());
      };

      socket.current.onerror = (event) => { 
        socket.current.close();
        socket.current = null;
        setIsConnecting(false);
      };

      socket.current.onclose = (event) => { 
        socket.current = null;
        setIsConnecting(false);
      };

      socket.current.onopen = () => { 
        setIsConnecting(false);
        setActiveMic('active');
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then((stream) => {
            recorder.current = new RecordRTC(stream, {
              type: 'audio',
              mimeType: 'audio/webm;codecs=pcm',
              recorderType: RecordRTC.StereoAudioRecorder,
              timeSlice: 250,
              desiredSampRate: 16000,
              numberOfAudioChannels: 1,
              bufferSize: 4096,
              audioBitsPerSecond: 128000,
              ondataavailable: (blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64data = reader.result;
                  if (socket.current) {
                    socket.current.send(JSON.stringify({ audio_data: base64data.split('base64,')[1] }));
                  }
                };
                reader.readAsDataURL(blob);
              },
            });
            recorder.current.startRecording();  
         
          })
          .catch((err) => console.error('getUserMedia error:', err));
      }; 
      setIsRecording(true);  
    } catch (error) {
      console.error('Error generating transcript:', error);
      setIsConnecting(false);
    }
  };

 
  const endTranscription = () => {
    if (socket.current) {
      socket.current.send(JSON.stringify({ terminate_session: true })); 
      socket.current.close();
      socket.current = null;
    }

    if (recorder.current) {
      recorder.current.stopRecording();
      recorder.current = null;
    }
    setActiveMic(null); 
    setIsRecording(false);
  };

  const handleMicClick = () => {
    if (isConnecting) { 
     // console.log('Connecting, please wait...');
      return;
    }

    if (activeMic) {
      if (answer.trim() === '') {
        setAnswer(''); // Set to empty string if only spaces
      } else {
        setAnswer(answer);
      } 
      endTranscription();
      setActiveMic(null);
    } else {
      generateTranscript();
      setActiveMic('connecting');
    }
  };

  const resetTranscription = () => {
    setAnswer('');
    setIsRecording(false);

    if (recorder.current) {
      recorder.current.stopRecording();
      recorder.current = null;
    }
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }

    setActiveMic(null);   
  };
    
  const handleTextSetAnswer = (e) => {   
    const AnswerTxt =  e.target.value; 
    setAnswer(AnswerTxt);  
    if (AnswerTxt.trim() === '') {
      setAnswer(''); // Set to empty string if only spaces
    } else {
      setAnswer(AnswerTxt);
    } 
    if (inputRefAnswer.current) {
      inputRefAnswer.current.focus();
    }  
  };  
  const handleKeyDown = (e) => { 
    if (!e.shiftKey && e.key === 'Enter') { 
          handleAnswerSubmit();
        }
        
        if (inputRefAnswer.current) {
          inputRefAnswer.current.focus();
        }
  };
  useEffect(() => {
    // Focus input field on initial render (optional)
    if (inputRefAnswer.current) {
      inputRefAnswer.current.focus();
    }
  }, [inputRefAnswer]); 
 

  useEffect(() => {
    return () => {
      if (recorder.current) {
        recorder.current.stopRecording(() => {
          recorder.current = null;
        });
      }
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, []);
  
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/dashboard`;
    navigate(path);
  }
  // Fetch illnesses
  const illnessesArray = async () => {
    const response = await axios.get(getBaseURL+'/illnesses'); 
    setIllnessesList(response.data.illnesses);
    setTimeout(() => setShowLoader(false), 3000);
  };
  const handleIllnessSelect = async (item) => {
    setSelectedIllness(item);

    const payload = {
      user_id: userId,
      illness: item,
      reports_id:reportId,
      conversation: []
    };
    setAnswerLoader(true);
    setQuestionLoader(true);

    try {
      const response = await axios.post(getBaseURL+'/chatbot', payload); 
      if (response.status === 200) {
        setQuestions(prevQuestions => [...prevQuestions, response.data]);
        setCurrentQuestionIndex(0);
      }
    } catch (error) {
       //console.error('Error posting data:', error);
    } finally {
      setQuestionLoader(false);
      setAnswerLoader(false);

    }
  };

  useEffect(() => {
    setShowLoader(true);
    try {
      illnessesArray();
    } catch (error) {
       //console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (inputRefAnswer.current) {
      inputRefAnswer.current.focus();
    } 
  }, [questions]);

  const handleAnswerSubmit = async () => {
    if (!answer.trim()) { 
      toast.error('Please enter your answer before submitting.', {
        position: toast.POSITION.TOP_RIGHT,
      });       
      return;
    }
    // Clear the msg variable
    if (inputRefAnswer.current) {
      inputRefAnswer.current.focus();  
    }

    const currentQuestion = questions[currentQuestionIndex];
     // Construct the payload
    const filteredConversation = questions.filter((item) => item.answer && item.answer.trim() !== ""); // Filter out questions with empty answers
 

    const payload = {
      user_id: userId,
      illness: selectedIllness,
      reports_id:reportId,
      conversation: [
        ...filteredConversation,
        { question: currentQuestion.question, answer: answer }
      ]
    };

    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[currentQuestionIndex].answer = answer;
      return updatedQuestions;
    }); 
    setAnswer(''); // Clear the input field
    setAnswerLoader(true);

    try {
      const response = await axios.post(getBaseURL+'/chatbot', payload);
       // //console.log("Response from POST API:", response.data);
       if (inputRefAnswer.current) {
        inputRefAnswer.current.focus(); 
      }
 
      if (response.status === 200) {
        if (response.data.status === "finish") {
          setMessageTxt(response.data.message);
          setFinishChat(true);
          setAnswerLoader(false);   
          setIsPopupOpen(true);  
          endTranscription();

        } else { 
          setAnswer('');
          resetTranscription();
          setQuestions(prevQuestions => [
            ...prevQuestions,
            response.data
          ]); 
          setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      
          // Focus on the input field after handling the response
          if (inputRefAnswer.current) {
            inputRefAnswer.current.focus();
          }
        } 
      }

    } catch (error) {
       //console.error('Error posting data:', error);
    } finally {
      setAnswer('');
      setAnswerLoader(false); 
      resetTranscription();
    }
  };
 
  
  useEffect(() => {
    if (questions.length > 0 && questions[currentQuestionIndex]?.status === "finish") {
      setFinishChat(true);
      setAnswerLoader(false);   
      setIsPopupOpen(true);  
      localStorage.removeItem('reportId');
    } 
      if (inputRefAnswer.current) {
        inputRefAnswer.current.focus();
      } 
  }, [questions, currentQuestionIndex]);

  
  const closePopup = () => { 
    setIsPopupOpen(false);  
    routeChange()
    setQuestions([])
    setCurrentQuestionIndex(null)
    localStorage.removeItem('reportId');
  };
  return (
    <div>
      <div className='container-fluid p-0'>
        <Header />
        <div className='container-fluid p-0 contentBody'>
          <div className="chatbotConversation">
            <h2>Presenting Symptoms</h2>

            <div className="messageListBox">
              <div className="chatbot">
                <span className="imgBox">
                  <img src={Icons[0].chatbot} alt="Chatbot" />
                </span>
                <span>
                  <b className="AI_dateTime">
                    AI 
                  </b>
                  {ShowLoader ? (
                    <div className="showLoaderDot">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : (
                    <>
                      <label className="quesTxt">
                      Hi, How may I help you today? You can select a symptom you're experiencing by clicking on any of the tabs to 
                      start a chat with our AI chatbot. If none of the options match your concern, simply click on the 'Other Problem' tab to begin.
                      {/* Hi, How may I help you today? You may select or type a symptom you're facing currently. */}
                      </label>
                      <div className="questOpt">
                        <b>Please select any</b>
                        <ul className={!selectedIllness ? 'illnessesListOpt' : 'illnessesListOpt selected'}>
                          {illnessesList?.map((item, index) => (
                            <li
                              key={index}
                              className={selectedIllness === item ? 'active' : 'disabled'}
                              onClick={() => handleIllnessSelect(item)}
                              style={{ pointerEvents: selectedIllness && selectedIllness !== item ? 'none' : 'auto' }}
                            >
                              <b>{item}</b>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </span>
              </div>

              {questions.length > 0 && (
                <div className="quesListDiv">
                  {questions.map((q, index) => (
                    <div key={index}>
                      <div className="chatbot aiQuest">
                        <span className="imgBox">
                          <img src={Icons[0].chatbot} alt="Chatbot" />
                        </span>
                        <span>
                          {nextQuestionLoader && currentQuestionIndex === index + 1 ? (
                            <div className="showLoaderDot">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          ) : (
                            <>
                              <b className="AI_dateTime">
                                AI 
                              </b>
                              <label className="quesTxt">{q.question}</label>
                            </>
                          )}
                        </span>
                      </div>
                      {q.answer && (
                        <div className="chatbot userReply">
                          <span className="imgBox">
                            <b>{UserName.split(' ').map(word => word.charAt(0).toUpperCase()).join('')}</b>
                           </span>
                          <span>
                            <b className="AI_dateTime">
                              YOU                             
                            </b>
                            <label className="quesTxt">{q.answer}</label>
                          </span>
                        </div>
                      )}
                      {nextQuestionLoader && currentQuestionIndex === index + 1 && (
                        <div className="chatbot aiQuest">
                          <span className="imgBox">
                            <img src={Icons[0].chatbot} alt="Chatbot" />
                          </span>
                          <span>
                            <div className="showLoaderDot">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
               {answerLoader ? ( 
                    <>
                    <div className= "quesListDiv " >
                        <div className={"chatbot aiQuest "  + ( FinishChat ? "d-none" : "")}>
                            <span className="imgBox">
                              <img src={Icons[0].chatbot} alt="Chatbot" />
                            </span>
                            <span> 
                                <div className="showLoaderDot">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div> 
                            </span>
                          </div>
                    </div>
                    </>):('')}
              <div ref={messageBoxRef}></div>
            </div> 
             
       <div className="submitQueryBoxDiv">
              <div className={"QueryInputDivBox " + ( FinishChat ? "d-none" : "")}>
                <div className="inputField">
                  <input
                    type="text"
                    ref={inputRefAnswer} 
                    placeholder={activeMic === 'active' ? 'Mic On ' : "Ask Assistance..."}  
                    value={answer}
                    onChange={handleTextSetAnswer}
                    onKeyDown={handleKeyDown} 
                    disabled={answerLoader || selectedIllness?.length === undefined}
                  />
                  
                  <a onClick={handleMicClick} className={(activeMic === 'active' ? 'deactiveBtnClass ' : 'activeBtnClass')} 
                   disabled={isConnecting || activeMic === 'connecting' || isRecording} >
                      <img src={Icons[0].micIcon} className="logoImg" alt="Mic" />
                    <b className={(activeMic === 'active' ? 'zoom-in-zoom-out' : '')}></b>
                  </a>
                </div>
                <button className="btnSendQuery" onClick={handleAnswerSubmit}>
                  {answerLoader ? (
                    <>
                      Loading
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <>
                      Send
                      <img src={Icons[0].submitArrow} className="logoImg" alt="Submit Arrow" />
                    </>
                  )}
                </button>
              </div>
      </div>
       
          </div>
        </div>
 
       {isPopupOpen ? (<>
        <div className="modal-overlay modelBoxDiv">
          <div className="innerDiv">
            <div className="modal-content">
              {/* <h2>{MessageTxt}</h2>  */}
              <span class="imgDiv">
              <img src={Icons[0].CheckIcon}  alt="" className="w-100" />
            </span>
            <ul className="successListPoint">
              <li>Thanks for completing online information.</li>
              <li>AIGI Care will reach out to you to schedule an online consultation with a provider at a mutually convenient time.</li>
              <li>You will only be billed after the online consultation is completed.</li>
              <li>If you have any questions, call us at 480-666-3333.</li>
            </ul> 
 
              <button onClick={closePopup} >Close</button>
            </div>
          </div>
        </div>
       </>): ('') } 

      </div>
    </div>
  );
}

export default ChatBot;
