import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../assest/icons";

function Header() {
  
  const usernameTxt =  JSON.parse(localStorage.getItem('UserProfile'));
  
  const [UserName, setUserName] = useState(usernameTxt.fullName); 
  let navigate = useNavigate(); 
  const handleSettings = () => {
    navigate("/userprofile"); 
  };   
  const handleInsurance = () => {
    navigate("/myinsurance"); 
  };
  const handleLogout = () => { 
    navigate("/");
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  const handleforgotpwd = () => { 
    navigate("/changepassword");
  }
  const handleHomeScreen = () => { 
    navigate("/dashboard");
  }
  
  return (
    <div> 
        <div className="containerDiv whiteBg headerDivBox" >
          <div className="leftDiv d-inline-block">
            <Link to="/dashboard">
              <img src={Icons[0].logoIcon} className="logoImg companyLogo" alt="" />
            </Link>
          </div>
        
          <ul className="rightDiv d-inline-block sidenavList"> 
          <li className='homeIconDiv' onClick={() => handleHomeScreen()}>
          <img src={Icons[0].homeIcon} className="logoImg " alt="" /> 
          </li>
          <li className="listOpt accordion ">
                  <span className="profileImg">
                      <b> 
                      {UserName.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }
                            </b> 
                   </span>  
                  <span className="ddownDiv form-group  dropdown-toggle toggle-select-result"
                    role="button"   data-bs-toggle="dropdown" > </span> 
                    <div
                id="regularCollapseDdown"
                className="accordion-collapse collapse dropdown-menu  "
              >
                <div className="accordion-body p-0">
                  <ul className="accordion mt-0" id="myAccordion1">
                      <li> <b><img src={Icons[0].userImg} className="logoImg" alt=""  style={{height:'22px'}} /></b> <a onClick={() => handleSettings()}>My Profile</a> </li>
                      <li> <b><img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" style={{height:'20px'}} /></b> <a onClick={() => handleInsurance()}>My Insurance</a> </li>
                      <li> <b> <img src={Icons[0].lockedImg} alt="" /></b> <a onClick={() => handleforgotpwd()}> Change Password</a> </li>
                      <hr></hr>
                      <li> <b><img src={Icons[0].logoutIcon} alt="" /></b> <a onClick={() => handleLogout()}>Logout</a> </li>
                  </ul>
                  <button className="btn-primary d-none" type="button">
                    Logout
                  </button>
                </div>
              </div>
              </li>
          </ul>
        </div> 
    </div>
  )
}

export default Header
