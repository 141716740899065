import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; 
import Header from './header'; 
import axios from 'axios';
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment'; 
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getBaseURL } from './baseUrl';
import LoaderSpinner from './loaderSpinner'; 


function UserMyInsurance() {
 
  const userDataInfo =  JSON.parse(localStorage.getItem('UserProfile'))
  // const myinsuranceData =  JSON.parse(localStorage.getItem('myinsuranceinfo'))
  const [myinsuranceData, setMyinsuranceData] = useState([]);  

  const userID =  JSON.parse(localStorage.getItem('UserId'));
  const [ShowLoaderPage, setShowLoaderPage] = useState(false);

  const location = useLocation(); 
 
  const [policyIDTxt, setpolicyIDTxt] = useState(myinsuranceData?.policy_id  || '');
  const [GroupIDTxt, setGroupIDTxt] = useState(myinsuranceData?.group_id || '');  
  const [GroupNameTxt, setGroupNameTxt] = useState(myinsuranceData?.group_name || '');
  const [InsuranceCarrierTxt, setInsuranceCarrierTxt] = useState(myinsuranceData?.insurance_carrier || '');
  const [DOBTxt, setDOBTxt] = useState(myinsuranceData?.expiry_date || '');
  const [selectedFrontImage, setSelectedFrontImage] = useState(myinsuranceData?.front_image || '');
  const [frontPreview, setFrontPreview] = useState(myinsuranceData?.front_image || '');
  const [selectedBackImage, setSelectedBackImage] = useState(myinsuranceData?.back_image || '');
  const [backPreview, setBackPreview] = useState(myinsuranceData?.back_image || '');
  const [DOBTxtEnter, setDOBTxtEnter] = useState('');
  
  const [profile, setProfile] = useState({
    policyIDTxt: myinsuranceData?.policy_id || '', 
    GroupIDTxt: myinsuranceData?.group_id || '', 
    GroupNameTxt: myinsuranceData?.group_name || '', 
    InsuranceCarrierTxt: myinsuranceData?.insurance_carrier || '',
    DOBTxt: myinsuranceData?.expiry_date || '',
    selectedFrontImage: myinsuranceData?.front_image || '',
    selectedBackImage: myinsuranceData?.back_image || '', 
  }); 
 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [originalProfile, setOriginalProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isNewCard, setNewCard] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState('');       
  const [ShowLoader, setShowLoader] = useState(false); 


  const [UserId, setUserId] = useState(userDataInfo?.user_id);   
  const [isValid, setIsValid] = useState(false);

  const frontFileInputRef = useRef(null);  // Ref for Front file input
  const backFileInputRef = useRef(null);   // Ref for Back file input
 
 // Separate states for immediate validation and form submission errors
 const [formChkErrors, setFormChkErrors] = useState({ frontError: '', backError: '' });

 const [isfrontFileSubmitted, setIsfrontFileSubmitted] = useState(false); 
 const [isbackFileSubmitted, setIsbackFileSubmitted] = useState(false);
 const [CheckExpiry, setCheckExpiry] = useState(false);  
  
 
    
  useEffect(() => { 
    // const myinsuranceData =  JSON.parse(localStorage.getItem('myinsuranceinfo'))
    handleMyInsurance() 
    if (myinsuranceData === null) {
      setIsEditing(true);  // Assuming setIsEditing is a function that updates a state
      setNewCard(true); 
    }
    localStorage.setItem('UserId', JSON.stringify(UserId));
    setProfile(myinsuranceData);
    setOriginalProfile(myinsuranceData);
    const currentDate = moment(); // Get today's date
    const expiry = moment(myinsuranceData.expiry_date); // Parse the expiry date using moment
    
    if (expiry.isAfter(currentDate, 'day')) {
      setCheckExpiry(true); // Expiry date is before today
    } else {
      setCheckExpiry(false); // Expiry date is today or in the future
    }
  }, [UserId]);
   
 
const formatDate = (date) => { 
  return date ? moment(date).format("YYYY-MM-DD") : '';
};
const handleChangeCancel = () => { 
    setIsEditing(!isEditing);
};
 
const handleMyInsurance = async () => {  
setShowLoaderPage(true)

  fetch(getBaseURL+'/get_insurance_by_userid/'+userID, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){ 
    let myinsuranceResp = data.data; 

    setpolicyIDTxt(myinsuranceResp?.policy_id); 
    setGroupIDTxt(myinsuranceResp?.group_id);
    setGroupNameTxt(myinsuranceResp?.group_name);
    setInsuranceCarrierTxt(myinsuranceResp?.insurance_carrier);
    setDOBTxt(myinsuranceResp?.expiry_date);
    setDOBTxtEnter(myinsuranceResp?.expiry_date);    
    setSelectedFrontImage(myinsuranceResp?.front_image);
    setFrontPreview(myinsuranceResp?.front_image)
    setSelectedBackImage(myinsuranceResp?.back_image); 
    setBackPreview(myinsuranceResp?.back_image); 

     // Update profile state
     setProfile((prevProfile) => ({
      ...prevProfile,
      policyIDTxt: myinsuranceResp?.policy_id || '',
      GroupIDTxt: myinsuranceResp?.group_id || '',
      GroupNameTxt: myinsuranceResp?.group_name || '',
      InsuranceCarrierTxt: myinsuranceResp?.insurance_carrier || '',
      DOBTxt: myinsuranceResp?.expiry_date || '',
      DOBTxtEnter: myinsuranceResp?.expiry_date || '',
      selectedFrontImage: myinsuranceResp?.front_image || '',
      selectedBackImage: myinsuranceResp?.back_image || '',
    }));

      localStorage.setItem('myinsuranceinfo', JSON.stringify(data.data));       
        setMyinsuranceData(data.data)
        const currentDate = moment(); // Get today's date
        const expiry = moment(data.data.expiry_date); // Parse the expiry date using moment
        
        if (expiry.isAfter(currentDate, 'day')) {
          setCheckExpiry(true); // Expiry date is before today
        } else {
          setCheckExpiry(false); // Expiry date is today or in the future
        }
setShowLoaderPage(false)

    } 
  })
  .catch((error) => {
    console.log('Error:', error);
  setShowLoaderPage(false)

  });
  
}; 
 
const handleChange = (e) => {
  const { name, value } = e.target; 
  setProfile((prevState) => ({
    ...prevState,
    [name]: value,
  }));
}; 
const handleSubmitProfileNew = (event) => { 
 
 
  event.preventDefault(); 
  
  let newDOBTxt = ''; 
  // Simple form validation
  let formErrors = {}; 
  setErrorMessage('');
  // Set form as submitted
  setIsSubmitted(true);
  setIsfrontFileSubmitted(true)
  setIsbackFileSubmitted(true)

  // Check if all date parts (YYYY, MM, DD) are filled out
  if (!YYYYTxt.trim() || !MMTxt.trim() || !DDTxt.trim()) {
    formErrors.DOBTxt = 'Please select a complete date (YYYY/MM/DD)';
  } else {
    const currentYear = moment().year();
    const year = parseInt(YYYYTxt, 10);
    const month = parseInt(MMTxt, 10);
    const day = parseInt(DDTxt, 10);
  
    const currentMoment = moment().startOf('day'); // Today's date
    const maxAllowedDate = moment().add(2, 'years').endOf('day'); // Max date 2 years from now
  
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      formErrors.DOBTxt = 'Invalid input. Ensure all fields are numbers';
    } else if (month < 1 || month > 12) {
      formErrors.DOBTxt = 'Invalid month. Month must be between 1 and 12';
    } else if (day < 1 || day > 31) {
      formErrors.DOBTxt = 'Invalid day. Day must be between 1 and 31';
    } else {
      const date = moment(`${YYYYTxt}-${MMTxt}-${DDTxt}`, 'YYYY-MM-DD');
  
      if (!date.isValid()) {
        formErrors.DOBTxt = 'Invalid date. Please check your input (e.g., Feb 30 is not valid)';
      } else if (date.isBefore(currentMoment, 'day')) {
        formErrors.DOBTxt = 'Date cannot be in the past';
      } else if (date.isAfter(maxAllowedDate, 'day')) {
        formErrors.DOBTxt = `Date cannot be more than ${maxAllowedDate.format('YYYY-MM-DD')}`;
      } else {
        // All checks passed
        const newDOBTxt = date.format('YYYY-MM-DD'); 
        setDOBTxt(newDOBTxt); // Update state with the valid date
        delete formErrors.DOBTxt; // Remove the error key when validation passes
      }
    }
  }
  
    
  if (!String(policyIDTxt).trim()) {
    formErrors.policyIDTxt = 'Please enter your Policy ID';
  }
  if (!String(GroupNameTxt).trim()) {
      formErrors.GroupNameTxt = 'Please enter your Group Name ';
  }   
  if (!String(InsuranceCarrierTxt).trim()) {
      formErrors.InsuranceCarrierTxt = 'Please enter your Insurance Carrier';
  }
 
  if (Object.keys(formErrors).length > 0) {
    console.log(JSON.stringify(formErrors) +'  -  '+ newDOBTxt )
      setError(formErrors);
      return;
  }
  setShowLoader(true) 

  // If validation passes, send data to API
//   const dataReq = {  
//     expiry_date: DOBTxt, 
//     group_id:GroupIDTxt,
//     group_name:GroupNameTxt,
//     policy_id:policyIDTxt,
//     insurance_carrier:InsuranceCarrierTxt,
//     user_id: UserId,     
// }; 
 

const formData = new FormData(); 

if (isNewCard) {
  formData.append('user_id', UserId); 
}
formData.append('expiry_date', newDOBTxt);
formData.append('group_id', 0);
formData.append('group_name', GroupNameTxt); 
formData.append('policy_id', policyIDTxt);
formData.append('insurance_carrier', InsuranceCarrierTxt);
formData.append('front_image', selectedFrontImage);    
formData.append('back_image', selectedBackImage);

const url = isNewCard 
? getBaseURL + '/insurance/upload11'  // POST for new card
: getBaseURL + '/update_insurance11/' + UserId;  // PUT for update

const method = isNewCard ? 'POST' : 'PUT';  // Choose method

// Log each entry in FormData for debugging
for (let [key, value] of formData.entries()) {
console.log(key, value);
}
 
fetch(url, {
  method: method, 
  body: formData,  // Send the constructed formData
})
.then(response => response.json())
.then(data => {
  if( data.status == 'success'){
    toast.success("Insurance details update successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });  
    localStorage.setItem('myinsuranceinfo', JSON.stringify(data)); 
        const currentDate = moment(); // Get today's date
        const expiry = moment(data.data.expiry_date); // Parse the expiry date using moment
        
        if (expiry.isAfter(currentDate, 'day')) {
          setCheckExpiry(true); // Expiry date is before today
        } else {
          setCheckExpiry(false); // Expiry date is today or in the future
        }
    
   
    setShowLoader(false) 
    setError('')
    handleCancel()
  }
  if( data.status == 'error'){ 
      toast.error(data.message, {
         position: toast.POSITION.TOP_RIGHT,
        });   
        setError('')
        setShowLoader(false)
  }
    // Optionally, you can redirect the user or show a success message
})
.catch((error) => {
    console.error('Error:', error);
    // Optionally, you can display an error message to the user
});

 


};

const handleSubmitProfile = (event) => {
  event.preventDefault();
  let newDOBTxt = '';
  let formErrors = {};
  
  // Validation logic
  if (!YYYYTxt.trim() || !MMTxt.trim() || !DDTxt.trim()) {
    formErrors.DOBTxt = 'Please select a complete date (YYYY/MM/DD)';
  } else {
    const year = parseInt(YYYYTxt, 10);
    const month = parseInt(MMTxt, 10);
    const day = parseInt(DDTxt, 10);
    const currentMoment = moment().startOf('day');
    const maxAllowedDate = moment().add(2, 'years').endOf('day');
    const minAllowedDate = moment().subtract(2, 'years').startOf('day');
    const date = moment(`${YYYYTxt}-${MMTxt}-${DDTxt}`, 'YYYY-MM-DD');

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      formErrors.DOBTxt = 'Invalid input. Ensure all fields are numbers';
    } else if (month < 1 || month > 12) {
      formErrors.DOBTxt = 'Invalid month. Month must be between 1 and 12';
    } else if (day < 1 || day > 31) {
      formErrors.DOBTxt = 'Invalid day. Day must be between 1 and 31';
    } else if (!date.isValid()) {
      formErrors.DOBTxt = 'Invalid date. Please check your input (e.g., Feb 30 is not valid)';
    } else if (date.isBefore(minAllowedDate, 'day')) {
      formErrors.DOBTxt = `Date cannot be before than ${minAllowedDate.format('YYYY-MM-DD')}`; // Check for past date
    } else if (date.isAfter(currentMoment, 'day')) {
      // formErrors.DOBTxt = `Date cannot be more than ${maxAllowedDate.format('YYYY-MM-DD')}`; // Check max date
      formErrors.DOBTxt = `Date cannot be from the future`; // Check max date

    } else {
      newDOBTxt = date.format('YYYY-MM-DD'); // Set newDOBTxt with the valid date
      setDOBTxt(newDOBTxt); // Update state
    }
  }


  

  // Check for other required fields
  if (!policyIDTxt.trim()) {
    formErrors.policyIDTxt = 'Please enter your Policy ID';
  }
  if (!GroupNameTxt.trim()) {
    formErrors.GroupNameTxt = 'Please enter your Group Name';
  }
  if (!InsuranceCarrierTxt.trim()) {
    formErrors.InsuranceCarrierTxt = 'Please enter your Insurance Carrier';
  }

  // Stop execution if there are validation errors
  if (Object.keys(formErrors).length > 0) {
    console.log('Form Errors:', formErrors);
    setError(formErrors);
    return;
  }

  // FormData creation and appending
  const formData = new FormData();
  if (isNewCard) {
    formData.append('user_id', UserId);
  }
  formData.append('expiry_date', newDOBTxt); // Append valid DOB
  formData.append('group_name', GroupNameTxt);
  formData.append('policy_id', policyIDTxt);
  formData.append('insurance_carrier', InsuranceCarrierTxt);
  formData.append('front_image', selectedFrontImage);
  formData.append('back_image', selectedBackImage);

  // Log formData for debugging
  for (let [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }

  const url = isNewCard
    ? getBaseURL + '/insurance/upload'
    : getBaseURL + '/update_insurance/' + UserId;

  fetch(url, {
    method: isNewCard ? 'POST' : 'PUT',
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      // if (data.status === 'success') {
      //   console.log('Successfully submitted:', data);
      //   toast.success('Insurance details updated successfully!');
      // } else {
      //   console.error('Error:', data.message);
      //   toast.error(data.message);
      // }

      if( data.status == 'success'){
        toast.success("Insurance details update successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });  
        localStorage.setItem('myinsuranceinfo', JSON.stringify(data)); 
            const currentDate = moment(); // Get today's date
            const expiry = moment(data.data.expiry_date); // Parse the expiry date using moment
            
            if (expiry.isAfter(currentDate, 'day')) {
              setCheckExpiry(true); // Expiry date is before today
            } else {
              setCheckExpiry(false); // Expiry date is today or in the future
            }
        
       
        setShowLoader(false) 
        setError('')
        handleCancel()
      }
      if( data.status == 'error'){ 
          toast.error(data.message, {
             position: toast.POSITION.TOP_RIGHT,
            });   
            setError('')
            setShowLoader(false)
      }


    })
    .catch((error) => {
      console.error('Error submitting form:', error);
    });
};


const handleCancel = () => { 
  // setProfile(originalProfile);
  // setIsEditing(false);
  // setError('') 
  window.location.reload(); 

};

const handleInsuraneCarrierChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z0-9\s]/g, ''); //.replace(/[^a-zA-Z\s]/g, '');
  setInsuranceCarrierTxt(value);   
};
 

const handleGroupNameChange = (event) => {
  // Remove non-text characters from the input value
  // const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  const value = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');//.replace(/[^a-zA-Z\s]/g, '');
  setGroupNameTxt(value); 
};


const InsuranceIdHandleChange = (e) => {
  // Get the new value
  const value = e.target.value;    

  // Regex to allow only alphanumeric characters, hyphens, and spaces
  const regex = /^[\w\s-]*$/;

  if (value === '' || regex.test(value)) {
    let newValue = value.replace(/\s+/g, ' '); 
    setpolicyIDTxt(newValue);
  }  
};

 
 



const yearRef = useRef(null);
const monthRef = useRef(null);
const dayRef = useRef(null);
  
const [YYYYTxt, setYYYYTxt] = useState('');
const [MMTxt, setMMTxt] = useState('');
const [DDTxt, setDDTxt] = useState('');

useEffect(() => { 
  if (DOBTxt) { 
    const [year, month, day] = DOBTxt.split('-');
    setYYYYTxt(year);
    setMMTxt(month);
    setDDTxt(day);
  }
}, [DOBTxt]); 

const handleInput = (e, nextRef, prevRef, setState) => {
    let value = e.target.value;
    const fieldName = e.target.placeholder;
    // Reset submission flag when user types
    setIsSubmitted(false); 

    // Update the state as the user types
    setState(value); 

  // Forward jump when the max length is reached
  // if (value.length === e.target.maxLength) {
    
  //   if (fieldName === "YYYY") {
  //     const currentYear = moment().year();
  //     const maxAllowedYear = currentYear + 2; // Allow up to 2 years from the current year
  //     if (parseInt(value, 10) > maxAllowedYear) {
  //       setErrorMessage(`Year cannot be more than ${maxAllowedYear}`);
  //       yearRef.current.focus();
  //       return;
  //     }
  //   }

  //   // Month Validation (MM)
  //   if (fieldName === "MM") {
  //     if (parseInt(value, 10) < 1 || parseInt(value, 10) > 12) {
  //       setErrorMessage("Month must be between 1 and 12");
  //       monthRef.current.focus();
  //       return;
  //     }
  //   }

  //   // Day Validation (DD)
  //   if (fieldName === "DD") {
  //     if (parseInt(value, 10) < 1 || parseInt(value, 10) > 31) {
  //       setErrorMessage("Day must be between 1 and 31");
  //       dayRef.current.focus();
  //       return;
  //     }
  //   }

  if (value.length === e.target.maxLength) { 
    const currentDate = moment();
    const enteredYear = parseInt(YYYYTxt, 10);
    const enteredMonth = parseInt(MMTxt, 10) - 1; 
   

    if (fieldName === "YYYY") {
        if (enteredYear < 1000 || enteredYear > 9999) {
            setErrorMessage("Invalid year");
            yearRef.current.focus();
            return;
        }
        if (enteredYear > currentDate.year()) {
            setErrorMessage("Year cannot be in the future");
            yearRef.current.focus();
            return;
        }
    }

    if (fieldName === "MM") {
      if (parseInt(value, 10) < 1 || parseInt(value, 10) > 12) {
          setErrorMessage("Month must be between 1 and 12");
          monthRef.current.focus();
          return;
      }
      if (enteredYear === currentDate.year() && parseInt(value, 10) > currentDate.month() + 1) {
          setErrorMessage("Month cannot be in the future");
          monthRef.current.focus();
          return;
      }
  }

  if (fieldName === "DD") {
    const currentDateN = moment().startOf("day");
    const enteredDayNum = parseInt(value, 10); // Convert input value to number
    const enteredDate = moment({
      year: enteredYear,
      month: enteredMonth,
      day: enteredDayNum  
    }).startOf("day");

  

    // Reject invalid day numbers immediately
    if (enteredDayNum < 1 || enteredDayNum > 31) {
        setErrorMessage("Day must be between 1 and 31"); 
        dayRef.current.focus();
        return;
    }


  if (enteredDate.isAfter(currentDateN, 'day')) {
    setErrorMessage("Date cannot be in the future");
    dayRef.current.focus();
    return;
}

    if (!enteredDate.isValid()) {
        setErrorMessage("Invalid date. Please check your input.");
        dayRef.current.focus();
        return;
    }
 
}

    // Clear error message if validation passes
    setErrorMessage("");
    if (nextRef) {
      nextRef.current.focus();
    }
  } 
  // Backward jump when "Backspace" is pressed and the input is empty
  else if (e.nativeEvent.inputType === "deleteContentBackward" && value.length === 0) {
    if (prevRef) {
      prevRef.current.focus();
    }
  }
 
};

  

const handleKeyDown = (e, nextRef, prevRef, setState) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Handle Right Arrow Key
  if (e.key === "ArrowRight") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    // Check for single-digit values in MM and DD
    if ((fieldName === "MM" || fieldName === "DD") && value.length === 1) {
      const paddedValue = `0${value}`; // Add leading zero
      setState(paddedValue); // Update state
      e.target.value = paddedValue; // Update input value
    }

    if (nextRef) {
      nextRef.current.focus();
    }
  }

  // Handle Left Arrow Key
  if (e.key === "ArrowLeft") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    // Check for single-digit values in MM and DD
    if ((fieldName === "MM" || fieldName === "DD") && value.length === 1) {
      const paddedValue = `0${value}`; // Add leading zero
      setState(paddedValue); // Update state
      e.target.value = paddedValue; // Update input value
    }

    if (prevRef) {
      prevRef.current.focus();
    }
  }
};

const handleBlur = (e, setState) => {
  const value = e.target.value;

  // Check if the value is a single digit
  if (value.length === 1 && parseInt(value, 10) > 0) {
    const paddedValue = `0${value}`; // Add leading zero
    setState(paddedValue); // Update state
    e.target.value = paddedValue; // Update input value
  }
};  

// Handles the file input change
const handleImageChange = (e, type) => {
  const file = e.target.files[0]; 
  let errors = { ...formChkErrors };

  // Reset submission flags
  if (type === 'front') setIsfrontFileSubmitted(false);
  if (type === 'back') setIsbackFileSubmitted(false);

  if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
    
    // Check if the same file is being uploaded for both front and back
    if (type === 'front' && selectedBackImage && selectedBackImage.name === file.name) {
      errors.frontError = 'Front image cannot be the same as the back image';
    } else if (type === 'back' && selectedFrontImage && selectedFrontImage.name === file.name) {
      errors.backError = 'Back image cannot be the same as the front image';
    } else {
      // Proceed with normal file upload handling
      if (type === 'front') {
        setSelectedFrontImage(file);
        setFrontPreview(URL.createObjectURL(file)); // Create Front preview URL
        errors.frontError = '';  // Clear any previous front image errors  
      } else if (type === 'back') {
        setSelectedBackImage(file);
        setBackPreview(URL.createObjectURL(file)); // Create Back preview URL
        errors.backError = '';  // Clear any previous back image errors
      }
    }

  } else {
    if (type === 'front') {
      errors.frontError = 'Please upload an image file (jpg or png)';
    } else if (type === 'back') {
      errors.backError = 'Please upload an image file (jpg or png)';
    }
  }

  setFormChkErrors(errors);  // Update form errors state
};

// Function to trigger the hidden file input click
const handleUploadClick = (type, e) => {
  e.stopPropagation(); // Stop the click event from bubbling
  e.preventDefault();  // Prevent default behavior if necessary

  if (type === 'front') {
    frontFileInputRef.current.click();  // Trigger Front input
  } else if (type === 'back') {
    backFileInputRef.current.click();   // Trigger Back input
  }
};

  // Reset file and preview
  const resetImage = (type) => {
    let errors = { ...formChkErrors };
    if (type === 'front') {
      setSelectedFrontImage(null);
      setFrontPreview(null);
      errors.frontError = '';  // Clear front image error when reset
    } else if (type === 'back') {
      setSelectedBackImage(null);
      setBackPreview(null);
      errors.backError = '';  // Clear back image error when reset
    }
    setFormChkErrors(errors);  // Update form errors state
  };



  return (
    <div > 
     <div className='container-fluid p-0 bgInnerDiv'>
        <Header />
        <div className='container-fluid p-0 contentBody  '>  
        {ShowLoaderPage && <LoaderSpinner />} 

        <div className='loginBoxDiv ForgotPassswordMain myinsuranceinfoMain whiteBgColor centerDivAlign'> 
        <h2>My Insurance
             <button className={'editIcon ' + (isEditing ?  'btnactive' : '') } onClick={() => setIsEditing(true)}>Edit
           <img src={isEditing ? Icons[0].editRed : Icons[0].editIcon } className="logoImg" alt="" /> 
           </button>
        </h2> 
        {CheckExpiry &&
              <h3 className='errorCardExpiry'  > <img src={Icons[0].errorImg} style={{width:'24px', marginRight:'5px'}} /> Health card has expired </h3>
        } 
       <form onSubmit={handleSubmitProfile}> 
     
       <fieldset className='editmyinsuranceinfo MyInsuranceInnerDiv'  id="sign_up" >
          <div className='inputDivBox' >
            <label>Insurance Carrier:</label>
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" />
                    <input type="text" value={InsuranceCarrierTxt} placeholder="Insurance Carrier" onChange={handleInsuraneCarrierChange} disabled={isEditing ? '' : 'true'}    />
            </div>
            {error.InsuranceCarrierTxt && <span  className="errorTxt" >{error.InsuranceCarrierTxt}</span>} 
          </div>
          <div className='inputDivBox' >
            <label>Policy ID:</label>
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    <img src={Icons[0].policyidIcon} className="logoImg" alt="" />
                    <input type="text" value={policyIDTxt} placeholder="Policy ID" disabled={isEditing ? '' : 'true'} onChange={InsuranceIdHandleChange}    /> 
                  </div>
                  {error.policyIDTxt && <span  className="errorTxt" >{error.policyIDTxt}</span>}  

          </div> 
          <div className='inputDivBox' >
            <label>Group Name:</label>
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    <img src={Icons[0].groupnameIcon} className="logoImg" alt="" />
                    <input type="text" value={GroupNameTxt} placeholder="Group Name" disabled={isEditing ? '' : 'true'} onChange={handleGroupNameChange}    /> 
                  </div>
                  {error.GroupNameTxt && <span  className="errorTxt" >{error.GroupNameTxt}</span>} 
          </div> 
          <div className='inputDivBox' >
            <label>
              {/* Expiry Validity */}
            Effective Date:</label>  
            {/* CheckExpiry */}
            <div className={"inputTxtNewBox  registerInputDiv " + (isEditing ? '' : 'disabled') + (CheckExpiry && ' dateExpiryTxt') }>
                        <img src={Icons[0].expirydateIcon} className="logoImg" alt="" />
                        {/* <input type="text" value={DOBTxt} placeholder="Date of Birth (YYYY-MM-DD)" disabled={isEditing ? '' : 'true'}  />  */}

                         <div className="timepicker">
                              <input
                                type="text"
                                className="hh"
                                maxLength="4"
                                placeholder="YYYY"
                                ref={yearRef}
                                value={YYYYTxt}
                                disabled={isEditing ? '' : 'true'} 
                                onInput={(e) => handleInput(e, monthRef, null, setYYYYTxt)}
                                onKeyDown={(e) => handleKeyDown(e, monthRef, null)}
                              />
                              /
                              <input
                                type="text"
                                className="mm"
                                maxLength="2"
                                placeholder="MM"
                                value={MMTxt}
                                ref={monthRef}
                                disabled={isEditing ? '' : 'true'} 
                                  onInput={(e) => handleInput(e, dayRef, yearRef, setMMTxt)}
                                  onKeyDown={(e) => handleKeyDown(e, dayRef, yearRef, setMMTxt)}
                              />
                              /
                              <input
                                type="text"
                                className="mm"
                                maxLength="2"
                                placeholder="DD"
                                ref={dayRef}
                                value={DDTxt}
                                disabled={isEditing ? '' : 'true'} 
                                onInput={(e) => handleInput(e, null, monthRef, setDDTxt)}
                                onKeyDown={(e) => handleKeyDown(e, null, monthRef, setDDTxt)}
                                onBlur={(e) => handleBlur(e, setDDTxt)} // Add onBlur event
                              />
                              <b style={{fontWeight:'500',fontSize: '14px', color:'#999'}}>
                              Effective Date
                              {/* Expiry Validity */}
                              </b>
                           
                            </div>   
                   </div>
                  {error.DOBTxt && <span  className="errorTxt" >{error.DOBTxt}</span>} 
          </div>
          <div className='inputDivBox' >
            <label>Front Side:</label> 
            <div className={"inputTxtBox cardImageDiv registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    {/* <img src={selectedFrontImage} className="logoImg" alt="" />  */}
                    <b className='myinsurancecardImg'>
                         {/* Custom Upload Button for Front Image */}
                    {!selectedFrontImage && ( 
                       <span className='uploadInnerDiv'> 
                          <img src={Icons[0].uploadIcon} className="logoImg uploadIcon" alt="" /> 
                          <span className='cardUploadTxt'>Insurance Card Front {" "}
                            <button className="cardImgBtn" onClick={(e) => handleUploadClick('front', e)}>
                                Browse
                            </button> 
                          </span> 
                          <span  className='cardUploadTxt'>File Format - PNG JPG | File Size - 5 MB (Max)</span>
                      
                        {/* Hidden Front file input */}
                        <input 
                          type="file" 
                          accept="image/jpeg, image/png" 
                          ref={frontFileInputRef} 
                          onChange={(e) => handleImageChange(e, 'front')}
                          style={{ display: 'none' }} 
                        />
                     </span>
                    )} 

                        
                    {frontPreview && (
                      <span className="previewImg"> 
                        <b><img src={frontPreview} alt="Front Preview" /></b> 
                        <button className={"removedBtn "+ (isEditing ? '' : 'd-none') } onClick={() => resetImage('front')}>&#10006;</button>
                      </span>
                    )}
                    </b>
                  </div>
                  {error.InsuranceIdTxt && <span  className="errorTxt" >{error.InsuranceIdTxt}</span>} 
          </div>
          
          <div className='inputDivBox' >
            <label>Back Side:</label> 
            <div className={"inputTxtBox cardImageDiv registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    {/* <img src={selectedBackImage} className="logoImg" alt="" />  */}
                   <b className='myinsurancecardImg'>
                     {/* Custom Upload Button for Back Image */}
                      {!selectedBackImage && (
                        <span className='uploadInnerDiv'> 
                          <img src={Icons[0].uploadIcon} className="logoImg uploadIcon" alt="" /> 
                          <span className='cardUploadTxt'>Insurance Card Back {" "}
                            <button className="cardImgBtn" onClick={(e) => handleUploadClick('back', e)}>
                                Browse
                            </button> 
                          </span> 
                          <span  className='cardUploadTxt'>File Format - PNG JPG | File Size - 5 MB (Max)</span>
                          {/* Hidden Back file input */}
                          <input 
                            type="file" 
                            accept="image/jpeg, image/png" 
                            ref={backFileInputRef} 
                            onChange={(e) => handleImageChange(e, 'back')}
                            style={{ display: 'none' }} 
                          />
                        </span>
                      )}

                      {backPreview && (
                        <span className="previewImg"> 
                          <b><img src={backPreview} alt="Back Preview" /></b>
                          <button className={"removedBtn "+ (isEditing ? '' : 'd-none') }  onClick={() => resetImage('back')}>&#10006;</button>
                        </span>
                      )}
                      </b>
                  </div>

                  {error.InsuranceIdTxt && <span  className="errorTxt" >{error.InsuranceIdTxt}</span>} 
          </div> 
       
         
          </fieldset>
{
  isEditing ? (<>
  <div className={"saveOptDivBox "}>
          {ShowLoader ? (<>
                      <button  className="   " > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button onClick={handleSubmitProfile} type="submit" >Save</button>
                    </>)} 
                  <button type="button" onClick={handleCancel}>Cancel</button>
            </div>
  </>) : ('')
}
          
          
        </form>
       
       

                 
      </div>
         </div>
    </div> 
    </div> 
  );
}

export default UserMyInsurance;
